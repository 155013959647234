<template>
	<div class="p-3 py-5" style="max-height: 100vh; overflow-y: auto;">
		<div class="row">
			<div class="col-12 px-4 text-left">
				<h4>{{ $t('flightView.disclaimerTitle') }}</h4>
				<p class="text-justify" v-html="$t('flightView.disclaimer')">
				</p>
			</div>
		</div>
		<div class="col-12 d-flex justify-content-between mt-3">
			<label class="d-flex flex-row align-items-center" style="gap: 10px">
				<input type="checkbox" v-model="dontShowAgainCheckbox" />
				{{$t('flightView.dontShowAgain')}}
			</label>
			<div class="d-flex align-items-center">
				<a v-on:click="$emit('close',{})" href="javascript:void(0)" class="btn btn-secondary text-center">
					<font-awesome-icon class="m-auto" icon="check-circle" />
					{{ $t('flightView.ok') }}
				</a>
			</div>
		</div>
	</div>
</template>

<script>


export default {
	name: "BeforeFlightDisclaimerModal",
	props: [],
	components: {


	},
	data() {
		return {
			dontShowAgainCheckbox: false
		}
	},
	mounted() {

	},
	watch:{
		dontShowAgainCheckbox(newValue) {
			window.consentedStorage.setItem("beforeFlightDisclaimerOff", newValue);
		}
	}
}
</script>

<style scoped>

</style>
