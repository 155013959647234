<template>
	<div class="plane-card-h" v-if="horizontal" @click.stop="onClick">
		<span class="new-indicator" v-if="!viewed">{{$t("planes.new")}}</span>
		<div class="image-box">
			<img :src="image" alt="" />
		</div>
		<div class="plane-card-body">
			<span class="regNum">
				{{p.registrationNumber}}
				<span class="verified-badge" v-if="p.verifiedAt" v-b-tooltip.hover :title="$t('planes.verifiedText')" >
					<font-awesome-icon icon="check-circle" class="ml-1" />
				</span>
			</span>
			<span class="model">{{p.producer}} {{p.model}}</span>
		</div>
		<div class="plane-actions">
			<slot />
		</div>
	</div>
	<div class="plane-card-v" @click.stop="onClick" v-else>
		<span class="new-indicator" v-if="!viewed">{{$t("planes.new")}}</span>
		<div class="plane-card-body">
			<div class="image-box">
				<img :src="image"  alt=""/>
			</div>
			<div class="plane-header">
				<div class="name">
					{{ p.registrationNumber }}
					<span class="verified-badge" v-if="p.verifiedAt" v-b-tooltip.hover :title="$t('planes.verifiedText')">
					<font-awesome-icon icon="check-circle" class="" />
				</span>
				</div>
				<div class="model">{{ p.producer }} {{ p.model }}</div>
			</div>
		</div>
		<div class="plane-actions">
			<slot></slot>
		</div>
	</div>
</template>

<script>
import { getPlaneIcon } from "@/plugins/planeUtils";


export default {
	name: 'PlaneCard',
	emits: ["click"],
	props: {
		horizontal: {
			type: Boolean,
			default: false
		},
		userPlane: {
			type: Object,
			required: false
		},
		plane: {
			type: Object,
			required: false
		}
	},
	methods: {
		onClick() {
			this.$emit("click", this.p);
		}
	},
	computed: {
		/** @type {Plane} */
		p() {
			if(!this.userPlane) return this.plane;
			return this.userPlane.plane;
		},
		image() {
			return getPlaneIcon(this.p.beaconType)
		},
		viewed() {
			if(!this.userPlane) return true;
			return this.userPlane.viewed;
		}
	}
}
</script>

<style scoped >
.plane-card-v {
	position: relative;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	transition: all 0.1s ease-in-out;
	padding: 20px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	gap: 1rem;
}
.plane-card-v:hover {
	scale: 1.03;
}

.new-indicator {
	position: absolute;
	top: 5px;
	right: 5px;
	background-color: #0086FF;
	border-radius: 5px;
	padding: 0.2rem 0.3rem;
	font-size: 0.5rem;
	color: white;

}

.plane-card-v .plane-card-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	flex: 1;
	width: 100%;;
}

.plane-card-v .plane-card-body .plane-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.plane-card-v .plane-card-body .plane-header .name {
	font-size: 20px;
	font-weight: 600;
	text-align:center;
	text-transform: uppercase;
}

.plane-card-v .plane-card-body .plane-header .model {
	font-size: 14px;
	font-weight: 400;
	color: #878789;
	text-align:center;
}

.plane-card-v .plane-card-body .image-box {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 0;
}

.plane-card-v .plane-card-body .image-box img {
	width: 32px;
	rotate: 45deg;
}

.plane-card-v .plane-actions {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	width: 100%;
}

.plane-card-v .plane-actions button {
	z-index: 99;
}

@media (max-width: 800px) {
	.plane-card-v .plane-card-body .image-box img {
		width: 24px;
	}

	.plane-card-v .plane-card-body .plane-header .name {
		font-size: 1rem;
	}
}

/* Horizontal */
.plane-card-h {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 0.5rem 1rem;
	border-radius: 8px;
	background-color: #fff;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
	border: 1px solid #97a3a4;
	cursor: pointer;
	transition: scale 0.1s ease-in-out;
	position: relative;
	gap: 1rem;
	text-align: left;
}

.plane-card-h:hover {
	background-color: #f1f1f1;
	scale: 1.004;
}

.plane-card-h .image-box {
	display: flex;
	justify-content: center;
	align-items: center;
}

.plane-card-h .image-box img {
	width: 24px;
	height: 24px;
	object-fit: contain;
	rotate: 45deg;
}

.plane-card-h .plane-card-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
}

.plane-card-h .plane-card-body .regNum {
	font-size: 1.2rem;
	font-weight: bold;
}

.plane-card-h .plane-card-body .model {
	font-size: 0.8rem;
}

.plane-card-h .plane-actions {
	display: flex;
	flex-grow: 0;
	gap: 0.5rem;
}

.verified-badge {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 0.2rem;
	font-size: 1rem;
	color: var(--primary);
	padding: 0.2rem 0.2rem;
	border-radius: 5px;
	cursor: pointer;
	position: relative;
	z-index: 999999 !important;
}



</style>
