<template>
	<div class="h-100 overflow-hidden">
			<h1 class="top-heading">{{$t('startFlightView.title')}}</h1>
			<div class="top-heading-indicators">
				<button class="btn btn-primary quick-start-btn" @click="changeTab('routeplan', false,true, true)" v-if="tab === 'routeplan'">
					{{ $t('startFlightView.quickFlight') }}
					<font-awesome-icon icon="plane-departure" class="ml-2" />
				</button>
				<hr class="w-100" v-if="tab === 'routeplan'" />
			</div>
			<transition-group tag="div" :class="{
				'stepper': true,
				'no-quickflight': tab !== 'routeplan',
			}" :name="disableAnimation ? 'none' : (slideback ? 'slideback' : 'slide')">
				<div class="tab routeplan-tab" v-if="tab === 'routeplan'" key="routeplan">
					<h6 class="tab-heading" >{{$t('startFlightView.chooseRoutePlan')}}</h6>

					<div class="tab-body-box">
						<div class="tab-body">

							<div class="routeplans">
								<Skeleton v-if="loadingRoutePlans" :count="1" height="150px" />
								<Skeleton v-if="loadingRoutePlans" :count="1" height="150px" />
								<Skeleton v-if="loadingRoutePlans" :count="1" height="150px" />
								<Skeleton v-if="loadingRoutePlans" :count="1" height="150px" />
								<Skeleton v-if="loadingRoutePlans" :count="1" height="150px" />
								<Skeleton v-if="loadingRoutePlans" :count="1" height="150px" />


								<template v-if="!loadingRoutePlans">
									<div :class="{
											routeplan: true,
											card: true,
											active: selectedPlan && selectedPlan.id === routePlan.id
										}" v-for="routePlan in routePlans" v-bind:key="routePlan.id" @click="selectPlan(routePlan)">
										<h1>
											{{ routePlan.name }}
										</h1>
										<h4 v-if="routePlan.totalDistance">
											{{ routePlan.totalDistance  | formatNumber('0') }} {{ routePlan.distanceUnit | unitSuffix }}
										</h4>
										<h4 v-else>
											-
										</h4>

										<div class="endpoints" v-if="routePlan.startComName || routePlan.endName">
											<span>{{routePlan.startComName || '?'}}</span>
											<font-awesome-icon icon="arrow-right" class="mx-2" />
											<span>{{routePlan.endName || '?'}}</span>
										</div>

									</div>
								</template>


							</div>
						</div>
						<div class="tab-actions">
							<button class="btn btn-light flex-1 w-100 " @click="changeTab('routeplan', false, true)">
								{{ $t('startFlightView.skip') }}
							</button>
							<button class="btn btn-primary w-100 " @click="changeTab('routeplan')">{{ $t('startFlightView.next') }}</button>
						</div>
					</div>
				</div>
				<div class="tab plane-tab" v-else-if="tab === 'plane'" key="plane">
					<h6 class="tab-heading">{{$t('startFlightView.choosePlane')}}</h6>

					<div class="tab-body-box">
						<div class="tab-body">

							<ServerError v-if="errorPlanes && !loadingPlanes" @retry="loadPlanes()" />

							<div class="planes" v-if="!errorPlanes || loadingPlanes">

								<Skeleton v-if="loadingPlanes" :count="1" height="150px" />
								<Skeleton v-if="loadingPlanes" :count="1" height="150px" />
								<Skeleton v-if="loadingPlanes" :count="1" height="150px" />
								<Skeleton v-if="loadingPlanes" :count="1" height="150px" />
								<Skeleton v-if="loadingPlanes" :count="1" height="150px" />
								<Skeleton v-if="loadingPlanes" :count="1" height="150px" />


								<template v-if="!loadingPlanes">
									<div :class="{
										plane: true,
										card: true,
										active: selectedPlane && selectedPlane.id === p.id
									}"  v-for="p in myPlanes" :key="p.id" @click="selectPlane(p)">
											<div class="plane-card-body">
												<div class="image-box">
													<img :src="getPlaneImage(p.beaconType)"  alt=""/>
												</div>
												<div class="plane-header">
													<div class="name">{{ p.registrationNumber }}</div>
													<div class="model">{{ p.producer }} {{ p.model }}</div>
												</div>
											</div>
										</div>
								</template>
							</div>
						</div>
						<div class="tab-actions">
							<button class="btn btn-light flex-1 w-100 " @click="changeTab('plane', false, true)">
								{{ $t('startFlightView.skip') }}
							</button>
							<div class="d-flex w-100 gap-4">
								<button class="btn btn-link" @click="changeTab('plane', true)" v-if="shouldShowBackButton('plane')">
									<font-awesome-icon icon="chevron-left" class="mr-2" />
									{{ $t('startFlightView.back') }}
								</button>
								<button class="btn btn-primary flex-1 w-100 " @click="changeTab('plane')">
									{{ $t('startFlightView.next') }}
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="tab summary-tab" v-else-if="tab === 'summary'" key="summary">
					<h6 class="tab-heading">{{$t('startFlightView.summary')}}</h6>

					<div class="tab-body-box">
						<div class="tab-body summary">
							<div class="">
								<span class="no-gps-message" v-if="noGPSinDevice">
										<font-awesome-icon icon="exclamation-circle" class="mr-2" />
										{{ $t('error.noGPSinDevice') }}
									</span>
								<div class="reg-num-box">
									<label for="regNum">{{$t('flightView.reg-number')}}</label>
									<InputText id="regNum" v-model="registrationNumber" :disabled="builtInMode || loading"  :class="{
										'w-100': true,
										'error': regError
									}" />
									<span class="error-text" v-if="regError">
										<font-awesome-icon icon="exclamation-circle" class="mr-2" />
										{{ regError }}
									</span>
								</div>
								<div class="beacon-types-box" v-if="!selectedPlane">
									<div class="beacon-types">
										<div :class="{
												'beacon-type': true,
												'card': true,
												active: beaconType === bt
											}" v-for="bt in beaconTypes" v-bind:key="bt" @click="beaconType = bt" >
											<img :src="getPlaneImage(bt)"  alt="" />
										</div>
									</div>
								</div>

								<div class="selected-routeplan-box" v-if="!quickStart && routePlans.length > 0">
									<div class="selected-routeplan">
										<div class="selected-header">
											<h6>{{ $t('startFlightView.routePlan') }}</h6>
											<button @click="onRoutePlanSelect" v-if="selectedPlan" :disabled="loading">
												{{ $t('startFlightView.change') }}
											</button>
											<button @click="onRoutePlanSelect" v-else :disabled="loading">
												{{ $t('startFlightView.select') }}
											</button>
										</div>
										<div v-if="selectedPlan" class="selected-routeplan-body">
											<div class="name">{{ selectedPlan.name }}</div>
											<div class="endpoints">
												{{ selectedPlan.totalDistance  | formatNumber('0') }} km
											</div>
										</div>
									</div>
								</div>

								<div class="selected-plane-box" v-if="!quickStart && myPlanes.length > 0">
									<div class="selected-plane">
										<div class="selected-header">
											<h6>{{ $t('startFlightView.plane') }}</h6>
											<button @click="onPlaneSelect" v-if="selectedPlane" :disabled="loading">
												{{ $t('startFlightView.change') }}
											</button>
											<button @click="onPlaneSelect" v-else :disabled="loading">
												{{ $t('startFlightView.select') }}
											</button>
										</div>
										<div v-if="selectedPlane" class="selected-plane-body">
											<img :src="getPlaneImage(selectedPlane.beaconType)"  alt=""/>
											<div class="name">{{ selectedPlane.producer }} {{ selectedPlane.model }}</div>
											<div class="reg-num">{{ selectedPlane.registrationNumber }}</div>
										</div>
										<div v-else-if="selectedPlan" class="selected-plane-body">
											<img :src="getPlaneImage('MOTORPLANE')"  alt=""/>
											<div class="name">{{ $t('startFlightView.unknown') }}</div>
											<div class="reg-num">{{ selectedPlan.planeRegistrationNumber }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="tab-actions">
              <div class="d-flex justify-content-center mikrotik align-content-center" v-if="isOnMikrotikNet">
                <Checkbox v-model="useMikrotik" :disabled="loading" :binary="true" id="useMikrotik"/>
                <label for="useMikrotik" class="m-0 ml-2">{{$t('startFlightView.useMikrotik')}}</label>
              </div>
							<div class="d-flex w-100 gap-4">
								<button class="btn btn-link" @click="changeTab('summary', true)" v-if="shouldShowBackButton('summary')" :disabled="loading">
									<font-awesome-icon icon="chevron-left" class="mr-2" />
									{{ $t('startFlightView.back') }}
								</button>
								<button class="btn btn-primary flex-1 w-100 " @click="startFlight()" :disabled="loading || noGPSinDevice">
									<font-awesome-icon icon="sync" size="lg" class="mr-0 btn-loading-icon" v-if="loading" />
									<font-awesome-icon icon="plane-departure" class="mr-2" v-else />
									{{ $t('startFlightView.start') }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</transition-group>

	</div>
</template>

<script>
import { LOCAL_STORAGE_SERVICE, READ_JSON_FILE, STORAGE_KEY } from '@/plugins/utils';
import { mapGetters } from "vuex";
import BeforeFlightDisclaimerModal from '@/components/BeforeFlightDisclaimerModal.vue'
import PlaneCard from "@/components/plane/PlaneCard.vue";
import { getPlaneIcon } from "@/plugins/planeUtils";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { Skeleton } from "vue-loading-skeleton";
import ServerError from "@/components/ServerError.vue";
import { isItBad } from "@/plugins/badwords";
import Checkbox from "primevue/checkbox";


export default {
	components: { ServerError, Skeleton, Checkbox },
	name: 'StartFlightView',
	data() {
		return {
			registrationNumber: '',
			/** @type {BeaconType|null} */
			beaconType: "MOTORPLANE",
			/** @type {BeaconType[]} */
			beaconTypes: [
				"MOTORPLANE",
				"GLIDER",
				"HAND_GLIDER",
				"HELICOPTER",
				"CAR",
				"JET",
			],
			/** @type {RoutePlan|null} */
			selectedPlan: null,
			useMikrotik: false,
			platform: window.platform,
			/** @type {Plane[]} */
			myPlanes: [],
			/** @type {RoutePlan[]} */
			routePlans: [],
			/** @type {Plane|null} */
			selectedPlane: null,
			builtInMode: false,
			noGPSinDevice: window.noGPSinDevice || false,
			isOnMikrotikNet: false,
			loading: false,
			disableAnimation: true,
			slideback: false,
			/** @type {('routeplan'|'plane'|'summary')} */
			tab: 'routeplan',
			disabledTabs: [],
			quickStart: false,
			regError: null,
			loadingRoutePlans: false,
			loadingPlanes: false,
			errorRoutePlans: false,
			errorPlanes: false,
		};
	},
	props: {
		app: Object
	},
	async created() {
		let currentRegistrationNumber = window.consentedStorage.getItem('currentRegistrationNumber');
		let currentRouteIdWithUuid = window.consentedStorage.getItem('currentRouteIdWithUuid');
		let currentBeaconType = window.consentedStorage.getItem('currentBeaconType');

		if (currentRegistrationNumber && currentRouteIdWithUuid) {
			// Van folyamatban lévő repülés, ezért nem indítunk újat.

			let currentRouteIsWithMikrotik = window.consentedStorage.getItem('currentRouteIsWithMikrotik');
			if (currentRouteIsWithMikrotik === 'true') {
				window.loadAppLink('r2gflightserver://start-mikrotik-gps-forward');
			} else {
				window.loadAppLink('r2gflightserver://resume-route?route-id-uuid=' + currentRouteIdWithUuid);
			}

			await this.$router.replace({ path: '/flying-map/' + currentRegistrationNumber + '/' + currentRouteIdWithUuid + '?beaconType=' + currentBeaconType });
			return;
		}


		await Promise.all([
			this.loadRoutePlans(),
			this.loadPlanes(),
		]);


		this.disabledTabs = [];
		if(this.routePlans.length === 0 ) {
			this.disabledTabs.push('routeplan');
			this.tab = 'plane';
		}
		if(this.myPlanes.length === 0) {
			this.disabledTabs.push('plane');
		}

		if(this.disabledTabs.includes("routeplan")) {
			this.tab = 'plane';
			if(this.disabledTabs.includes("plane")) {
				this.tab = 'summary';
			}
		}
	},
	async mounted() {
		setTimeout(() => {
			this.disableAnimation = false;
		}, 300);
		let isOnNetwork = await this.$mikrotik.isOnMikrotikNetwork();
		this.isOnMikrotikNet = isOnNetwork;
		this.useMikrotik = window.noGPSinDevice || false;
		if (isOnNetwork) {
			this.registrationNumber = await this.$mikrotik.getEnvironmentVariable('r2gfsRegistrationNumber');
			this.builtInMode = (await this.$mikrotik.getEnvironmentVariable('r2gfsBuiltIn')) === '1';
		}
	},
	watch: {
		registrationNumber() {
			this.regError = null;
		},
		getStartGPS(startGPS) {
			this.$data.currentAltitude = Math.round((startGPS || {}).alt || 0)
		},
	},
	methods: {
		onPlaneSelect() {
			this.slideback = true;
			this.tab = 'plane';
		},
		onRoutePlanSelect() {
			this.slideback = true;
			this.tab = 'routeplan';
		},
		getPlaneImage(bt) {
			return getPlaneIcon(bt)
		},
		selectPlane(p) {
			if(this.selectedPlane && this.selectedPlane.id === p.id) {
				this.selectedPlane = null;
				this.registrationNumber = (this.selectedPlan || {}).planeRegistrationNumber || '';
				return;
			}
			this.registrationNumber = p.registrationNumber;
			this.selectedPlane = p;
		},
		selectPlan(rp) {
			if(this.selectedPlan && this.selectedPlan.id === rp.id) {
				this.selectedPlan = null;
				this.selectedPlane = null;
				this.registrationNumber = '';
				return;
			}
			this.selectedPlane = this.myPlanes.find(p => p.registrationNumber === rp.planeRegistrationNumber);
			if(!this.selectedPlane) {
				this.selectedPlane = null;
				this.registrationNumber = rp.planeRegistrationNumber;
			}else{
				this.registrationNumber = this.selectedPlane.registrationNumber;
			}

			this.selectedPlan = rp;
		},
		shouldShowBackButton(tab) {
			return tab !== 'routeplan' && this.getPrevTab(tab) !== tab;
		},
		getPrevTab(currentTab) {
			// Change to routeplan tab if its from plane tab and the routeplan tab is not disabled
			if(currentTab === 'plane' && !this.disabledTabs.includes('routeplan')) {
				return 'routeplan';
			}

			// Change to plane tab if its from summary tab and the plane tab is not disabled
			if(currentTab === 'summary' && !this.disabledTabs.includes('plane')) {
				return 'plane';

			// Change to routeplan tab if its from summary tab and the routeplan tab is not disabled and the plane tab is disabled
			}else if(currentTab === 'summary' && !this.disabledTabs.includes('routeplan')) {
				return 'routeplan';
			}

			return currentTab;
		},
		getNextTab(currentTab, shouldSkip) {
			if(currentTab === 'routeplan' && this.selectedPlan && !shouldSkip) {
				return 'summary';
			}

			// Change to plane if its from route plan tab and the plane tab is not disabled
			if(currentTab === 'routeplan' && !this.disabledTabs.includes('plane')) {
				return 'plane';
			}else if(currentTab === 'routeplan') {
				return 'summary';
			}



			// Change to summary if its from plane tab
			if(currentTab === 'plane') {
				return 'summary';
			}


			return currentTab;
		},
		changeTab(currentTab, shouldGoBack = false, shouldSkip = false, fullSkip = false) {
			this.slideback = shouldGoBack;

			this.tab = shouldGoBack ? this.getPrevTab(currentTab) : this.getNextTab(currentTab, shouldSkip);
			if(fullSkip) {
				this.tab = 'summary';
				this.quickStart = true;
				this.selectedPlane = null;
				this.selectedPlan = null;
				this.registrationNumber = '';
				window.newEvent('QUICK_FLIGHT')
			}
			else{
				this.quickStart = false;
			}



			if (shouldSkip) {
				if(currentTab === 'routeplan') {
					this.selectedPlan = null;
					this.selectedPlane = null;
					this.registrationNumber = '';
					window.newEvent('SKIP_ROUTE_PLAN')
				}
				if(currentTab === 'plane') {
					this.selectedPlane = null;
					this.registrationNumber = '';
					window.newEvent('SKIP_PLANE')
				}
			}
		},
		changePlane() {
			if (!this.builtInMode) {
				this.registrationNumber = this.selectedPlane.registrationNumber;
				this.beaconType = this.selectedPlane.beaconType || 'MOTORPLANE';
			}
		},
		changePlanSelect() {
			if (this.selectedPlan != null) {
				if (!this.builtInMode) {
					this.registrationNumber = this.selectedPlan.planeRegistrationNumber;
				}
				LOCAL_STORAGE_SERVICE.setItem(STORAGE_KEY.SELECTED_ROUTE_PLANE, this.selectedPlan);

				const storedRoutePlans = LOCAL_STORAGE_SERVICE.getItem(STORAGE_KEY.ROUTE_PLANE, {});
				storedRoutePlans['rp_' + this.selectedPlan.id] = this.selectedPlan;
				LOCAL_STORAGE_SERVICE.setItem(STORAGE_KEY.ROUTE_PLANE, storedRoutePlans);
			}
		},
		async startFlight(disableModal) {
			this.registrationNumber = this.registrationNumber.toUpperCase();
			this.loading = true;

			if (!disableModal && window.consentedStorage.getItem('beforeFlightDisclaimerOff') != "true") {
				this.$modal.show(BeforeFlightDisclaimerModal, {}, {
					height: 'auto',
					width: '900px',
					clickToClose: false,
					adaptive: true,
				}, {
					'before-close': () => {
						this.startFlight(true)
					}
				});
				return;
			}

			this.app.clearMessages();

			if (!this.registrationNumber) {
				this.regError = this.$t('error.noRegistrationNumber');
				this.loading = false;
				return;
			}

			if(isItBad(this.registrationNumber)) {
				this.regError = this.$t('error.badRegistrationNumber');
				this.loading = false;
				return;
			}

			if (!this.selectedPlan) {
				LOCAL_STORAGE_SERVICE.removeItem(STORAGE_KEY.SELECTED_ROUTE_PLANE);
			}

			let routeIdWithUuid = null;
			if (!window.canOfflineStart) {
				console.log("Starting route the old way");
				routeIdWithUuid = await this.$rest.startRoute({
					registrationNumber: this.registrationNumber,
					routePlanId: (this.selectedPlan || {}).id,
					beaconType: this.beaconType,
					fcmToken: window.consentedStorage.getItem('fcmToken'),
				});
				console.log("Route started with id: " + routeIdWithUuid);
				if(!routeIdWithUuid) {
					this.loading = false;
					this.$emit("addMessage", {
						severity: "error",
						message: this.$t('flightView.start-error'),
					})
					return;
				}
			}

			const routeParams = new URLSearchParams({
				registrationNumber: this.registrationNumber,
				planId: this.selectedPlan ? this.selectedPlan.id : null,
				beaconType: this.beaconType,
				useMikrotik: this.useMikrotik ? 'true' : 'false',
				"route-id-uuid": routeIdWithUuid,
			}).toString();


			window.consentedStorage.setItem('currentRegistrationNumber', this.registrationNumber);
			window.consentedStorage.setItem('currentRouteIdWithUuid', routeIdWithUuid);
			window.consentedStorage.setItem('currentBeaconType', this.beaconType);
			window.consentedStorage.setItem('currentRouteStart', new Date().getTime());

			let mikrotikStarted = false;

			if (this.useMikrotik) {
				console.log("Starting route with mikrotik");
				let isOnNetwork = await this.$mikrotik.isOnMikrotikNetwork();
				this.isOnMikrotikNet = isOnNetwork;
				if (!isOnNetwork) {
					this.loading = false;
					return;
				}

				await this.$mikrotik.setEnvironmentVariable('r2gfsRegistrationNumber', this.registrationNumber);
				await this.$mikrotik.setEnvironmentVariable('r2gfsFlightId', '');
				await this.$mikrotik.setEnvironmentVariable('r2gfsRouteIdWithUuid', routeIdWithUuid);
				await this.$mikrotik.enableScheduler('r2gfsGps');

				window.consentedStorage.setItem('currentRouteIsWithMikrotik', true);

				window.loadAppLink('r2gflightserver://start-mikrotik-gps-forward');

				mikrotikStarted = true;
			}

			if (!mikrotikStarted) {
				window.consentedStorage.setItem('currentRouteIsWithMikrotik', false);
				window.loadAppLink(`r2gflightserver://start-route?${routeParams}`);
			}

			window.newEvent('START_FLIGHT', {
				registrationNumber: this.registrationNumber,
				planeId: this.selectedPlane ? this.selectedPlane.id : null,
				routePlanId: this.selectedPlan ? this.selectedPlan.id : null,
				quickFlight: this.quickStart ? 'true' : 'false',
				beaconType: this.beaconType,
				useMikrotik: this.useMikrotik ? 'true' : 'false',
			})

			console.log("Starting route with params: " + routeParams);
			LOCAL_STORAGE_SERVICE.setItem(STORAGE_KEY.SELECTED_ROUTE_PLANE, this.selectedPlan)

			this.$router.push({ path: '/flying-map/' + this.registrationNumber + '/' + routeIdWithUuid + '?beaconType=' + this.beaconType });

			this.loading = false;
		},


		async loadRoutePlans() {
			this.loadingRoutePlans = true;
			const routePlans = await this.$rest.getRoutePlansForUser();

			this.routePlans = (routePlans || []).filter(rp => rp.routePlanElements.length > 0)

			if (this.routePlans.length === 0) {
				this.routePlans = Object.values(LOCAL_STORAGE_SERVICE.getItem(STORAGE_KEY.ROUTE_PLANE, {}));
			}

			this.loadingRoutePlans = false;
		},

		async loadPlanes() {
			this.loadingPlanes = true;

			const ups = await this.$rest.loadMyUserPlanes();
			if(!ups) {
				this.errorPlanes = true;
			}

			this.myPlanes = (ups || []).map(up => up.plane);
			this.loadingPlanes = false;
		}
	}
};
</script>

<style scoped>

.top-heading{
	font-size: 1.5rem;
	font-weight: 600;
	margin-bottom: 1rem;
	text-align: center;
	text-transform: uppercase;
}

.tab-heading{
	font-size: 1.2rem;
	font-weight: 400;
	color: #6c757d;
	margin-bottom: 1rem;
	text-align: center;
}


.stepper {
	position: relative;
	height: calc(100% - 192px);
	width: 100%;
}

.stepper.no-quickflight {
	height: calc(100% - 90px);
}

.tab {
	width: 100%;
	height: 100%;
	position: absolute;
	background: white;
}

.tab-dots {
	display: none;
	justify-content: center;
	align-items: center;
	margin-bottom: 1rem;
		gap: 1rem;
}

.tab-dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #6c757d;
	transition: all 0.2s ease-in-out;
}

.tab-dot.active {
	background-color: var(--primary);
}

.quick-start-btn {
    font-size: 0.8rem;
    padding: 1rem 1.2rem;
    position: relative;
		margin-bottom: 1rem;
		width: 100%;
}
.quick-start-btn:active, .quick-start-btn:focus {
    outline: none;
    box-shadow: none;
}

.top-heading-indicators {
    display: flex;
		flex-direction: column;
		align-items: center;
    position: relative;
}

@media screen and (max-width: 500px) {
		.tab-dots {
				gap: 0.5rem;
		}
		.tab-dot {
			width: 8px;
			height: 8px;
		}
}


.slide-leave-active,
.slide-enter-active {
	transition: 0.3s;
}
.slide-enter {
	transform: translate(calc(100% + 300px), 0);
}
.slide-leave-to {
	transform: translate(calc(-100% - 300px), 0);
}

.slideback-leave-active,
.slideback-enter-active {
    transition: 0.3s;
}
.slideback-enter {
    transform: translate(calc(-100% - 300px), 0);
}
.slideback-leave-to {
    transform: translate(calc(100% + 300px), 0);
}


.tab-body-box {
	display: flex;
	flex-direction: column;
	height: 100%;
	gap: 1rem;
}

.tab-body{
	flex-grow: 1;
	overflow-y: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	padding: 0.2rem;
}

.tab-body::-webkit-scrollbar {
	display: none;
}

.tab-actions {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	margin-top: 1rem;
	gap: 1rem;
	flex-shrink: 0;
	padding: 0 0.1rem;
}

.routeplans {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 1rem;
	overflow-y: auto;
	padding: 0.2rem;
	justify-content: center;
	align-items: flex-start;
}

.planes {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 1rem;
	overflow-y: auto;
	padding: 0.2rem;
	justify-content: center;
	align-items: flex-start;
}

.card {
	padding: 1rem;
	border-radius: 0.5rem;
	border: 1px solid #dee2e6;
	cursor: pointer;
	transition: border-color 0.1s ease-in-out;
}


.card:hover {
	border-color: var(--primary);
}

.card.active {
	border-color: var(--primary);
}

/* Outline (bc safari likes to not work) */
.card.active::before {
	content: "";
	position: absolute;
	top: -1px;
	right: -1px;
	bottom: -1px;
	left: -1px;
	border: 1px solid var(--primary);
	border-radius: 0.5rem;
}

.routeplan h1 {
	font-size: 1.2rem;
	font-weight: 600;
	margin-bottom: 0.5rem;
	text-align: center;
}

.routeplan h4 {
	font-size: 1rem;
	font-weight: 400;
	margin-bottom: 0.5rem;
	text-align: center;
}

.routeplan .endpoints {
	font-size: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
		flex-wrap: wrap;
}



@media (max-width: 500px) {
	.top-heading {
		font-size: 1.2rem;
	}

	.tab-heading {
		font-size: 1rem;
	}

	.routeplan h1 {
		font-size: 1rem;
	}

	.routeplan h4 {
		font-size: 0.8rem;
	}

	.routeplan .endpoints {
		font-size: 0.8rem;
	}
}

.plane .plane-card-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	flex: 1;
	width: 100%;;
}

.plane .plane-card-body .plane-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.plane .plane-card-body .plane-header .name {
	font-size: 20px;
	font-weight: 600;
	text-align:center;
	text-transform: uppercase;
}

.plane .plane-card-body .plane-header .model {
	font-size: 14px;
	font-weight: 400;
	color: #878789;
	text-align:center;
}

.plane .plane-card-body .image-box {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 0;
}

.plane .plane-card-body .image-box img {
	width: 32px;
	rotate: 45deg;
}


.summary label {
	font-size: 0.7rem;
	font-weight: 700;
	margin-bottom: 0rem;
	text-transform: uppercase;
}

.mikrotik label {
  font-size: 0.8rem;
  font-weight: 700;
  margin-bottom: 0rem;
  text-transform: uppercase;
}

.summary #regNum {
	background: #f8f9fa;
	border: none;
	box-shadow: 0px 1px 1px #ced4da;
	border-radius: 0.3rem;
	padding: 0.75rem 1rem;
	font-size: 0.8rem;
	font-weight: 400;
	color: #495057;
	text-transform: uppercase;
}

.summary .beacon-types-box {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
}

.summary .beacon-types {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	justify-content: flex-start;
	align-items: center;
	gap: 0.7rem;
	overflow-x: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	max-width: 400px;
	padding: 0.2rem 0.1rem;
}

@media screen and (max-width: 360px) {
	.summary .beacon-types {
		grid-template-columns: repeat(5, 1fr);
	}
}


.summary .beacon-types::-webkit-scrollbar {
    display: none;
}

.summary .beacon-types .beacon-type {
	transition: all 0.2s ease-in-out;
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	padding: 0.5rem;
}

.summary .beacon-types .beacon-type img {
		width: 100%;
		rotate: 45deg;
}

.selected-plane-box {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.selected-plane {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
	max-width: 600px;
	width: 100%;
	border: 1px solid #dee2e6;
	border-radius: 0.5rem;
	padding: 1rem;
}

.selected-plane-box .selected-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.selected-plane-box .selected-header h6 {
	font-size: 0.7rem;
	font-weight: 700;
	margin-bottom: 0rem;
	text-transform: uppercase;
}

.selected-plane-box .selected-header button {
	background: none;
	border: none;
	font-size: 0.7rem;
	font-weight: 700;
	margin-bottom: 0rem;
	text-transform: uppercase;
	color: var(--primary);
	translation: all 0.2s ease-in-out;
}

.selected-plane-box .selected-header button:active:focus {
		transform: scale(0.98);
}


.selected-plane-body {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
	gap: 1rem;
		width: 100%;
}

.selected-plane-body img {
	width: 36px;
	rotate: 45deg;
}

.selected-plane-body .name {
	font-size: 0.9rem;
	font-weight: 400;
	text-align:left;
	color: #878789;
	text-transform: uppercase;
	flex: 1;
}

.selected-plane-body .reg-num {
	font-size: 1.1rem;
	font-weight: 600;
	text-align:right;
}

.selected-plane-body .reg-num {
    font-size: 1.1rem;
    font-weight: 600;
    text-align:right;
}



.selected-routeplan-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.selected-routeplan {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    max-width: 600px;
    width: 100%;
    border: 1px solid #dee2e6;
    border-radius: 0.5rem;
    padding: 1rem;
}

.selected-routeplan-box .selected-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.selected-routeplan-box .selected-header h6 {
    font-size: 0.7rem;
    font-weight: 700;
    margin-bottom: 0rem;
    text-transform: uppercase;
}

.selected-routeplan-box .selected-header button {
    background: none;
    border: none;
    font-size: 0.7rem;
    font-weight: 700;
    margin-bottom: 0rem;
    text-transform: uppercase;
    color: var(--primary);
    translation: all 0.2s ease-in-out;
}

.selected-routeplan-box .selected-header button:active:focus {
    transform: scale(0.98);
}


.selected-routeplan-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
    width: 100%;
}

.selected-routeplan-body img {
    width: 36px;
    rotate: 45deg;
}

.selected-routeplan-body .name {
    font-size: 0.9rem;
    font-weight: 400;
    text-align:left;
    color: #878789;
    text-transform: uppercase;
    flex: 1;
}

.selected-routeplan-body .reg-num {
    font-size: 1.1rem;
    font-weight: 600;
    text-align:right;
}

.selected-routeplan-body .reg-num {
    font-size: 1.1rem;
    font-weight: 600;
    text-align:right;
}


.btn-light {
	background: none;
	border: 1px solid #dee2e6;
	color: #6c757d;
	margin: 0 0.5rem;
}



.error-text {
	color: red;
	font-size: 0.8rem;
	font-weight: 600;
	margin-top: 0.5rem;
}

.error{
	outline: 1px solid red !important;
}


.no-gps-message {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	background: #f8f9fa;
	border: 3px solid red;
	border-radius: 0.5rem;
	padding: 1rem;
	margin-bottom: 1rem;
}

.no-gps-message svg {
	width: 32px;
	height: 32px;
}

</style>
